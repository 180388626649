function Navbar() {
  return (
    <div>
      <div className="flex pr-20 pl-20 pt-4 pb-4 justify-evenly items-center phone:hidden">
        <a href="#hero" className="font-GothamBold text-lg text-[#EAD300]">
          HOME
        </a>
        <a href="#about" className="font-GothamBold text-lg text-[#313D5B]">
          ABOUT
        </a>
        <a href="#faq" className="font-GothamBold text-lg text-[#313D5B]">
          FAQ
        </a>
        <a href="#wilayas" className="font-GothamBold text-lg text-[#313D5B]">
          Wilayas
        </a>
        <a href="#workshops" className="font-GothamBold text-lg text-[#313D5B]">
          Workshops
        </a>
        <a href="#partners" className="font-GothamBold text-lg text-[#313D5B]">
          Partners
        </a>
        <a href="#contact" className="font-GothamBold text-lg text-[#313D5B]">
          Contact
        </a>
        <a
          href="/"
          className="no-underline border-none bg-transparent w-48 h-8 relative"
        >
          <div className="h-10 w-48 rounded-lg border-[3px] border-[#313D5B] absolute -top-1 -left-1"></div>
          <div className="bg-[#EAD300] pt-1 pb-1 pr-12 pl-12 rounded-lg z-10 absolute top-0 left-0">
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSetPNTr4UX9zCpd6lfjJ0HyVFUSf-lsZD3l2Kzl0f4O5S82xw/viewform?usp=sf_link"
              rel="noreferrer"
            >
              <p className="font-GothamBold text-lg text-[#313D5B]">REGISTER</p>
            </a>
          </div>
        </a>
      </div>
      <img
        className="w-16 p-4 hidden phone:inline"
        src="/images/menu_icon.svg"
        alt="NavBar"
      />
    </div>
  );
}

export default Navbar;

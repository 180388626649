import { Pagination } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "../css/styles.css";

function WorkshopsSection({workshops}){
    return(
        <div className='relative'>
            <div id='workshops' className="flex flex-col items-center pt-24 mb-24">
                <p className="font-GothamBold text-4xl text-[#0D99E7]">Workshops</p>
                <div className="m-8 p-4 rounded-lg border-dashed border-[2px] border-[#0D99E7] w-[80%] text-center workshops-box">
                <Swiper slidesPerView={1} spaceBetween={30} pagination={{clickable: true}} modules={[Pagination]} className="mySwiper">
                    {
                        workshops.map((workshop,index) => (
                            <SwiperSlide key={index}>
                                <div className='flex flex-col items-center'>
                                    <p className='font-GothamBold text-2xl text-[#313D5B] mb-2'>{workshop.name}</p>
                                    <p className='font-GothamBold text-xl text-[#0D99E7] mb-8'>{workshop.trainer}</p>
                                    <p className='font-GothamBook text-lg text-[#313D5B] max-w-md mb-12'>{workshop.description}</p>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </div>
            </div>
        </div>
    );
}

export default WorkshopsSection;
import AboutSection from "../components/AboutSection";
import ContactSection from "../components/ContactSection";
import FAQSection from "../components/FAQSection";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/NavBar";
import PartnersSection from "../components/PartnersSection";
import WilayasSection from "../components/WilayasSection";
import WorkshopsSection from "../components/WorkshopsSection";
import { partners } from "../constants/partners";
import { questions } from "../constants/questions";
import { wilayas } from "../constants/wilayas";
import { workshops } from "../constants/workshops";

function MainPage(){
    return(
        <div className="relative">
            <Navbar/>
            <HeroSection/>
            <AboutSection/>
            <WilayasSection wilayas={wilayas}/>
            <PartnersSection partners={partners}/>
            <WorkshopsSection workshops={workshops}/>
            <FAQSection questions={questions}/>
            <ContactSection/>
        </div>
    );
}

export default MainPage;
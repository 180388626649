function AboutSection() {
  return (
    <div className="relative">
      <div id="about" className="flex flex-col items-center mb-16 pt-16">
        <div className="relative w-[120px] h-11 mb-8 text-center">
          <p className="font-GothamLight text-4xl text-[#0D99E7] absolute top-[3px] -left-[3px] font-thin">
            About
          </p>
          <p className="font-GothamBold text-4xl text-[#0D99E7] absolute">
            About
          </p>
        </div>
        <div className="flex mb-12 justify-evenly items-center w-full p-4 phone:flex-col phone:items-center">
          <img
            className="h-80 rounded-lg p-2 border-[2px] border-dashed border-[#313D5B]"
            src="/images/IMG_4528.jpg"
            alt="CSE"
          />
          <div className="flex flex-col items-center p-4">
            <div className="relative w-[150px] h-11 mb-2 text-center">
              <p className="font-GothamLight text-3xl text-[#0D99E7] absolute top-1 -left-[3px] font-thin">
                CSE Club
              </p>
              <p className="font-GothamBold text-3xl text-[#313D5B] absolute">
                CSE Club
              </p>
            </div>
            <div className="w-full text-center">
              <p className="font-GothamBook text-[#313D5B] text-2xl mb-6 phone:text-xl">
                The CSE is a student club at the Higher National School of
                Computer Science in Algiers for students passionate about
                technology. It has gained national and international recognition
                by organizing major tech events, including the largest hackathon
                in Algeria. The club is now considered one of the most active
                and largest student clubs in the country.
              </p>
              <a
                href="https://www.instagram.com/cse.club/"
                rel="noreferrer"
                target="_blank"
                className="inline-block w-[242px] h-8 relative"
              >
                <div className="h-10 w-[242px] rounded-lg border-[3px] border-[#313D5B] absolute -top-1 -left-1"></div>
                <div className="bg-[#EAD300] pt-1 pb-1 pr-10 pl-10 rounded-lg z-10 absolute top-0 left-0">
                  <p className="font-GothamBold text-lg text-[#313D5B]">
                    DISCOVER MORE
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="flex p-4 items-center phone:flex-col phone:items-center">
          <div className="flex flex-col items-center p-4">
            <div className="relative w-[310px] h-11 mb-2 text-center">
              <p className="font-GothamLight text-3xl text-[#0D99E7] absolute top-1 -left-[3px] font-thin">
                CSE Around Algeria
              </p>
              <p className="font-GothamBold text-3xl text-[#313D5B] absolute">
                CSE Around Algeria
              </p>
            </div>
            <div className="w-full text-center">
              <p className="font-GothamBook text-[#313D5B] text-2xl mb-6 phone:text-xl">
                CSE Around Algeria aims to unite Algerian students who are
                interested in technology by hosting multiple workshops
                simultaneously in various regions of the country on April 2nd.
                The primary goal of the event is to promote collaboration and
                education among students who share a common interest in the tech
                industry.
              </p>
            </div>
          </div>
          <iframe
            className="rounded-lg border-dashed border-[2px] border-[#0D99E7] p-2 w-full"
            height="250"
            title="Teaser"
            src="https://www.youtube.com/embed/OAxtZ-fT0jQ"
          ></iframe>
        </div>
      </div>
      <img className="absolute top-0 -z-10" src="/images/sun2.svg" alt="Sun" />
      <img
        className="absolute top-0 -z-10"
        src="/images/lines2.svg"
        alt="Sun"
      />
      <img
        className="absolute top-0 right-0 -z-10"
        src="/images/clouds.svg"
        alt="Clouds"
      />
      <img
        className="absolute bottom-0 left-0 -z-10"
        src="/images/clouds2.svg"
        alt="Clouds"
      />
    </div>
  );
}

export default AboutSection;

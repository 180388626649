import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../css/styles.css";
import useWindowDimensions from "../hooks/useWindowDimensions";

function PartnersSection({ partners }) {
  const { width } = useWindowDimensions();

  return (
    <div className="relative">
      <div id="partners" className="flex flex-col items-center pt-32 mb-24">
        <p className="font-GothamBold text-4xl text-[#0D99E7]">Partners</p>
        <div className="m-8 p-4 w-[90%]">
          <Swiper
            slidesPerView={width >= 800 ? 3 : 1}
            spaceBetween={30}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {partners.map((partner, index) => (
              <SwiperSlide key={index}>
                <div className="h-96  bg-white border-dashed border-[#0D99E7] border-2 rounded-md shadow-md mb-12 flex justify-center p-4">
                  <img
                    className="object-contain"
                    src={partner.image}
                    alt={`Partner${index}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <img
        className="absolute -top-20 left-0 -z-10 w-64"
        src="/images/clouds2.svg"
        alt="Clouds"
      />
      <img
        className="absolute top-0 -z-10 w-44"
        src="/images/sun2.svg"
        alt="Sun"
      />
      <img
        className="absolute top-0 -z-10 w-44"
        src="/images/lines2.svg"
        alt="Sun"
      />
    </div>
  );
}

export default PartnersSection;

const workshops = [
  {
    name: "Introduction to Python",
    trainer: "",
    description:
      "This workshop serves as a beginner's guide to the Python programming language. Participants will gain a foundational understanding of the language's syntax and structure, as well as its applications in various domains such as data analysis, scientific computing, and web development.",
  },
  {
    name: "Introduction to machine learning",
    trainer: "",
    description:
      "This workshop provides a comprehensive introduction to machine learning, covering the basic concepts and techniques used in the field. By the end of this workshop, participants will have a solid understanding of machine learning fundamentals and their practical applications.",
  },
  {
    name: "Photography",
    trainer: "",
    description:
      "This workshop is designed to help participants improve their photography skills, regardless of their level of experience. It covers both the fundamentals of photography, such as composition, lighting, and exposure, as well as more advanced techniques like editing and post-processing. By the end of the workshop, participants will have gained valuable insights and practical skills to take their photography to the next level.",
  },
  {
    name: "Introduction to Web Development",
    trainer: "",
    description:
      "A session that is designed to provide an introduction to the basics of building websites and web applications. It is aimed at individuals who are new to the field of web development, as well as those who want to refresh their knowledge and skills.",
  },
];

export { workshops };

const wilayas = [
  {
    image: "/images/Alger.jpg",
    name: "Alger",
    description: "Introduction to Web Development",
  },
  {
    image: "/images/Batna.jpg",
    name: "Batna",
    description: "Introduction to Web Development",
  },
  {
    image: "/images/Blida.jpg",
    name: "Blida",
    description: "Photography fundamentals",
  },
  {
    image: "/images/Setif.jpg",
    name: "Sétif",
    description: "Introduction to Cloud",
  },
  {
    image: "/images/Medea.jpg",
    name: "Médea",
    description: "Introduction to Machine Learning",
  },
  {
    image: "/images/Tlemcen.jpg",
    name: "Tlemcen",
    description: "Introduction to programming with python",
  },
  {
    image: "/images/Djelfa.jpg",
    name: "Djelfa",
    description: "Photography Fundamentals",
  },
  {
    image: "/images/Chlef.jpg",
    name: "Chlef",
    description: "Introduction to Machine Learning",
  },
  {
    image: "/images/Tamenrasset.jpg",
    name: "Tamanrasset",
    description: "Introduction to programming with python",
  },
];

export { wilayas };

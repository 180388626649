const questions = [
  {
    question: "What is CSE Around Algeria?",
    answer: `CSE Around Algeria is an event that brings together Algerian students who are interested in technology. The main purpose of the event is to foster collaboration and education among students who share a passion for the tech industry.`,
  },
  {
    question: "Where will it be?",
    answer: `The CSE Around Algeria event will be taking place in multiple locations throughout the country.
Alger , Blida, Sétif, Djelfa, Médéa, Chlef, Batna, Tlemcen, Tamanrasset`,
  },
  {
    question: "How can I participate?",
    answer: `To participate in the event, fill out the form at the top of the website.`,
  },
  {
    question: "Is there a fee to participate in CSE Around Algeria?",
    answer: `No, all of our events are free of charge.`,
  },
];

export { questions };

function HeroSection() {
  return (
    <div className="relative">
      <div
        id="hero"
        className="flex justify-evenly pt-20 pl-20 pr-20 phone:flex-col phone:items-center"
      >
        <div>
          <div className="relative w-full h-22 mb-8 phone:mb-20">
            <p className="font-GothamLight text-6xl text-[#0D99E7] absolute -top-[3px] -left-[3px] font-thin phone:text-4xl">
              CSE AROUND ALGERIA
            </p>
            <p className="font-GothamBold text-6xl text-[#313D5B] absolute phone:text-4xl">
              CSE AROUND ALGERIA
            </p>
          </div>
          <div className="w-full pt-28 flex flex-col phone:items-center">
            <div className="max-w-xl">
              <p className="font-GothamBook text-[#313D5B] text-2xl mb-6 phone:text-center">
                CSE Around Algeria is a tech-oriented event that seeks to bring
                together Algerian students by organizing various workshops
                simultaneously across different regions of the country.
              </p>
            </div>
            <a
              href="/"
              className="no-underline border-none bg-transparent w-56 h-8 relative mb-4"
            >
              <div className="h-10 w-56 rounded-lg border-[3px] border-[#313D5B] absolute -top-1 -left-1"></div>
              <div className="bg-[#EAD300] pt-1 pb-1 pr-16 pl-16 rounded-lg z-10 absolute top-0 left-0">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSetPNTr4UX9zCpd6lfjJ0HyVFUSf-lsZD3l2Kzl0f4O5S82xw/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="font-GothamBold text-lg text-[#313D5B]">
                    REGISTER
                  </p>
                </a>
              </div>
            </a>
          </div>
        </div>
        <img
          className="w-[400px]"
          src="/images/event_logo.svg"
          alt="Event Logo"
        />
      </div>
      <img
        className="absolute -top-10 right-0 -z-10 w-60"
        src="/images/clouds.svg"
        alt="Clouds"
      />
      <img
        className="absolute top-0 -z-10 -left-10"
        src="/images/sun.svg"
        alt="Sun"
      />
      <img
        className="absolute top-20 -z-10 right-28 w-16"
        src="/images/sun4.svg"
        alt="Sun"
      />
    </div>
  );
}

export default HeroSection;

const partners = [
  {
    image: "/images/Allin-min.png",
  },
  {
    image: "/images/Almaahed-min.png",
  },
  {
    image: "/images/LeanrinLOGO-min.png",
  },
  {
    image: "/images/Logo Youthink -min.PNG",
  },
  {
    image: "/images/LOGO_ONE_CITY-min.png",
  },
  {
    image: "/images/Noor_academy-min.png",
  },
  {
    image: "/images/Setif_developers_Group-min.png",
  },
  {
    image: "/images/Wemed-min.png",
  },
];

export { partners };

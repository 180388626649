import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../css/styles.css";

import useWindowDimensions from "../hooks/useWindowDimensions";

function FAQSection({ questions }) {
  const { width } = useWindowDimensions();

  return (
    <div className="relative">
      <div id="faq" className="flex flex-col items-center pt-24 mb-24">
        <p className="font-GothamBold text-4xl text-[#0D99E7]">FAQ</p>
        <div className="m-8 p-8 text-center bg-[#3A4856] rounded-lg w-[80%]">
          <Swiper
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={width >= 800 ? true : false}
            modules={width >= 800 ? [Navigation, Pagination] : [Pagination]}
            className="faqSwiper"
          >
            {questions.map((question, index) => (
              <SwiperSlide key={index}>
                <div className="flex flex-col items-center">
                  <p className="font-GothamBold text-2xl text-white mb-8">
                    {question.question}
                  </p>
                  <p className="font-GothamLight text-xl text-white max-w-xl mb-16">
                    {question.answer}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <img
        className="absolute -top-20 right-0 -z-10 h-80"
        src="/images/clouds.svg"
        alt="Clouds"
      />
      <img
        className="absolute bottom-0 left-0 -z-10 h-80"
        src="/images/clouds2.svg"
        alt="Clouds"
      />
    </div>
  );
}

export default FAQSection;

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../css/styles.css";
import useWindowDimensions from "../hooks/useWindowDimensions";

function WilayasSection({ wilayas }) {
  const { width } = useWindowDimensions();

  return (
    <div className="relative">
      <div id="wilayas" className="flex flex-col items-center pt-24 mb-24">
        <p className="font-GothamBold text-4xl text-[#0D99E7]">Wilayas</p>
        <div className="m-8 w-[80%] max-w-2xl">
          <Swiper
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={width >= 800 ? true : false}
            modules={width >= 800 ? [Navigation, Pagination] : [Pagination]}
            className="wilayaSwiper"
          >
            {wilayas.map((wilaya, index) => (
              <SwiperSlide key={index} className="flex justify-center">
                <div className="flex flex-col items-center mb-16 rounded-lg shadow-lg shadow-[#0D99E730] ml-32 mr-32 phone:ml-1 phone:mr-1 overflow-hidden">
                  <img
                    src={wilaya.image}
                    className="w-full h-96 object-cover"
                    alt={wilaya.name}
                  />
                  <div className="p-8">
                    <p className="font-Gotham text-2xl text-[#313D5B] mb-8">
                      {wilaya.name}
                    </p>
                    <p className="font-GothamLight text-xl text-[#313D5B] max-w-xl">
                      {wilaya.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <img
        className="absolute -top-20 right-0 -z-10 h-80"
        src="/images/clouds.svg"
        alt="Clouds"
      />
      <img
        className="absolute bottom-0 left-0 -z-10 h-80 phone:hidden"
        src="/images/clouds2.svg"
        alt="Clouds"
      />
    </div>
  );
}

export default WilayasSection;

function ContactSection(){
    return(
        <div className="relative">
            <div id="contact" className="flex flex-col items-center">
                <p className="font-GothamBold text-3xl text-[#0D99E7] mb-4">Still have Questions ?</p>
                <p className="font-GothamBold text-2xl text-[#0D99E7]">Contact Us</p>
                <div className="flex w-full justify-center p-16 phone:flex-col phone:items-center">
                    <img className="max-h-64 mr-8" src="/images/cse_logo.svg" alt="Event Logo"/>
                    <img className="max-h-64" src="/images/event_logo.svg" alt="Event Logo"/>
                </div>
                <div className="flex w-full justify-center p-8">
                    <a href="https://www.instagram.com/cse.club/" rel="noreferrer" target="_blank" className="bg-white p-3 rounded-full shadow-md ml-3 mr-3">
                        <img className="w-7 h-7" src="/images/Instagram.svg" alt="Instagram"/>
                    </a>
                    <a href="https://www.linkedin.com/company/cse-club/mycompany/" rel="noreferrer" target="_blank" className="bg-white p-3 rounded-full shadow-md ml-3 mr-3">
                        <img className="w-7 h-7" src="/images/Linkedin.svg" alt="Linkedin"/>
                    </a>
                    <a href="https://www.facebook.com/club.scientifique.esi/" rel="noreferrer" target="_blank" className="bg-white p-3 rounded-full shadow-md ml-3 mr-3">
                        <img className="w-7 h-7" src="/images/Facebook.svg" alt="Facebook"/>
                    </a>
                    <a href="https://www.youtube.com/@ClubScientifiqueESI-CSE" rel="noreferrer" target="_blank" className="bg-white p-3 rounded-full shadow-md ml-3 mr-3">
                        <img className="w-7 h-7" src="/images/Youtube.svg" alt="Youtube"/>
                    </a>
                </div>
            </div>
            <img className="absolute -top-20 right-0 -z-10 h-80" src="/images/clouds.svg" alt="Clouds"/>
            <img className="absolute bottom-0 left-0 -z-10 h-80" src="/images/clouds2.svg" alt="Clouds"/>
        </div>
    );
}

export default ContactSection;